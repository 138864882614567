.Parent {
    display: flex;
    flex-direction: row;
    border:1px solid grey
}

.child1 {
    width: 20%;
    min-width:100px;
    height: 100vh;
    text-align: left;
    border-right:1px solid grey
}

.child2 {
    width: 80%;
    height: 100vh;
    padding:10px
}