/* #example {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.collage-maker {
  display: flex;
  border: 5px solid #000;
} */

.collage {
  display: flex;
}

.image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: lightgray;

}

.solid {
  background-size: cover;
  background-position: center;
  background-color: rgb(221, 160, 95);
}

.resizer {
  flex-shrink: 0;

  &.row {
    width: 5px;
  }

  &.column {
    height: 5px;
  }
}