/* .snap-button{
    border-radius: 8px;
    background-color: #ffc300;
    color: black;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    width: 80%;
}
#snap-label{
    background-color: #ffc300;
} */
/* .css-kl1xo3{
margin-top: ;
} */


@media (max-width: 1440px) {
  /* For mobile devices */
  .MuiContainer-maxWidthSm {
      padding: 20px 120px !important; /* Adjust top and bottom padding as needed */
  }
  .MuiGrid-container {
      padding: 20px 120px !important; /* Adjust top and bottom padding as needed */
  }
}
@media (max-width: 600px) {
  /* For mobile devices */
  .MuiContainer-maxWidthSm {
      padding: 20px 32px !important; /* Adjust top and bottom padding as needed */
  }
  .MuiGrid-container {
      padding: 20px 32px !important; /* Adjust top and bottom padding as needed */
  }
}
